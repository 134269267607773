import React, {useEffect, useState} from "react";
import {Box, IconButton, useTheme} from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import {useTranslation} from "react-i18next";
import SupportRequestDialog from "./SupportRequestDialog";

function ActionIconButtons() {
  const theme = useTheme();
  const [showScrollToTop, setShowScrollToTop] = useState(false);
  const [supportRequestOpen, setSupportRequestOpen] = useState(false);
  const {t} = useTranslation("translation");

  useEffect(() => {
    function handleScroll() {
      if (window.scrollY > 1200) {
        setShowScrollToTop(true);
      } else {
        setShowScrollToTop(false);
      }
    }

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo({top: 0, behavior: "smooth"});
  };

  const handleOpenSupportRequest = (event) => {
    event.preventDefault();

    setSupportRequestOpen(true);
  };

  const handleCloseDialog = () => {
    setSupportRequestOpen(false);
  };

  return (
    <>
      <Box
        display="flex"
        flexDirection={"column"}
        gap={4}
        sx={{
          position: "fixed",
          bottom: theme.spacing(4),
          right: theme.spacing(4),
          zIndex: (theme) => theme.zIndex.drawer + 2,
        }}>
        {showScrollToTop && (
          <IconButton
            onClick={handleScrollToTop}
            size="large"
            variant="contained"
            disableRipple={true}
            title={t("scroll_to_top")}
            sx={{
              color: theme.palette.secondary.contrastText,
              backgroundColor: theme.palette.secondary.main,
            }}
          >
            <ArrowUpwardIcon />
          </IconButton>
        )}
        <IconButton
          onClick={handleOpenSupportRequest}
          size="large"
          variant="contained"
          disableRipple={true}
          title={t("contact_an_expert")}
          sx={{
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.primary.main,
          }}
        >
          <SupportAgentIcon />
        </IconButton>
      </Box>
      <SupportRequestDialog
        open={supportRequestOpen}
        onClose={handleCloseDialog}
      />
    </>
  );
}

export default ActionIconButtons;
